import Heading from "../../../../globalComponents/molecules/Heading";
import ConnectivityNearAreas from "./ConnectivityNearAreas";

import { extractNearAreasAttributes } from "./helper";
import "./mapConnectivity.css";

const MapConnectivity = ({ projectData }) => {
  const CONNECTIVITY = extractNearAreasAttributes(projectData?.Near_Areas);

  return (
    <section className="row">
      <div className="col-12 col-xs-12 col-lg-6 col-md-5 col-sm-6 px-sm-0">
        <iframe
          className="locationGoogleMap"
          key="orion-one-32-map"
          title="googleMap"
          loading="lazy"
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
          // src="https://www.google.com/maps/embed/v1/place?key=API_KEY&q=Space+Needle,Seattle+WA"
          src={
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2948.1245849874126!2d77.37470891518883!3d28.511731676735508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce7109fa35ab7%3A0xa13b73629628829c!2sOrion%20One%2032!5e0!3m2!1sen!2sin!4v1690865310490!5m2!1sen!2sin"
          }
        />
      </div>
      <div className="col-12 col-lg-6 col-md-7 col-sm-6 pl-sm-0">
        <Heading
          text="Near By Attractions"
          fontSize="1.25rem"
          className="fw-bold mt-1"
        />
        <ConnectivityNearAreas connectedAreas={CONNECTIVITY} />
      </div>
    </section>
  );
};

export default MapConnectivity;
