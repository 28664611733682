import { Suspense, useState,lazy} from 'react';

import STATIC_PATCH_IMG from "./patch.webp";
import './patchButton.css';

const EventPopUp = lazy(() => import("../homePage/EventPopUp"));

const PatchButton = () => {
  const [isEventOpen, setIsEventOpen] = useState(false);

  const handleOpen = () => setIsEventOpen(true)
  const handleClose = () => setIsEventOpen(false)

  const PATCH_IMG = "https://www.wealth-clinic.com/wc-static-image/patch.webp" || STATIC_PATCH_IMG
  
  return (
    <>
      <div className='patchContainer' onClick={handleOpen}>
        <div className='patchCard'>
          <img alt='' src={PATCH_IMG} className='img-fluid' />
        </div>
      </div>
   <Suspense fallback="">
        <EventPopUp  isStatus={isEventOpen} onClose={handleClose} />
      </Suspense>
      {isEventOpen && <EventPopUp isStatus={isEventOpen} onClose={handleClose} />}
    </>
  );
};

export default PatchButton;
