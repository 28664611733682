import { toast } from "react-toastify";
import { useState } from "react";

import InputCustom from "../molecules/InputCustom";
import ButtonDarkBlue from "../molecules/ButtonDarkBlue";
import { CompanyLogoSection } from "./CallBackPopUpForm";

import { submissionOnZoho } from "../../../assets/constants/zoho";
import { validateForm } from "./services/formValidation";

const initialFormFields = {
  name: "",
  phone: "",
  email: "",
};

// const isFieldEmpty = (newObj) => {
//   return newObj.email.trim() && newObj.phone.trim() && newObj.name.trim();
// };

const centerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const callBackFormContainer = {
  ...centerStyle,
  flexDirection: "column",
  background: "#fff",
  width: "300px",
  height: "350px",
  borderRadius: ".25rem",
};

const CallBackForm = ({
  onClose,
  hideCloseBtn = true,
  styleForm,
  formBtnText,
  formName = "",
  formTitleClass = "",
  formTitle,
  logoClass,
  logoSrc,
  ...props
}) => {
  const [formValues, setFormValues] = useState(initialFormFields);
  const { name, phone, email } = formValues;
  const [isBtnDisable, setIsBtnDisable] = useState(false);

  const handleSubmitForm = async (event) => {
    event.preventDefault();
    const formValidationErrors = validateForm(formValues);
    // if (!isFieldEmpty(formValues)) {
    //   alert("Something is missing");
    //   return;
    // }
    if (Object.keys(formValidationErrors).length === 0) {
      setIsBtnDisable(true);
      await submissionOnZoho(formValues);
      toast("Form Submitted Successfully");
      setIsBtnDisable(false);
      // sendWhatsApp(formField);
      setFormValues(initialFormFields);
    }

    // if (window.Email) {
    //   sendEmail(formField);
    // } else {
    //   console.error("window.Email is not defined");
    // }
  };

  const handleInputChange = (event) => {
    setFormValues({
      formName,
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <div className="center-item h-100" {...props}>
      <div style={callBackFormContainer} className={`${styleForm} center-item`}>
        <CompanyLogoSection
          logoSrc={logoSrc}
          logoClass={logoClass}
          onClose={onClose}
          hideCloseBtn={hideCloseBtn}
        />
        {formTitle && <div className={formTitleClass}>{formTitle}</div>}
        <form onSubmit={handleSubmitForm}>
          <InputCustom
            placeholder="Enter Name"
            className="border px-3 rounded-2 w-100"
            value={name}
            name="name"
            onChange={handleInputChange}
          />
          <InputCustom
            placeholder="Enter Phone"
            className="border px-3 rounded-2"
            value={phone}
            name="phone"
            onChange={handleInputChange}
            required
          />
          <InputCustom
            placeholder="Enter Email"
            className="border px-3 rounded-2 w-100"
            value={email}
            name="email"
            onChange={handleInputChange}
            required
          />
          <ButtonDarkBlue
            name={formBtnText || "Get Call Back"}
            className={`${isBtnDisable && ""} formBtn rounded-2`}
            disabled={isBtnDisable}
          />
        </form>
      </div>
    </div>
  );
};

export default CallBackForm;
